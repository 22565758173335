<template>
    <div>
        <div class="d-flex px-2 small-text">
            <span>Structure Level:</span>
            <span class="ms-auto">
                <span>{{ getLinkedLevel }}</span>
            </span>
        </div>
        <div class="d-flex px-2 small-text">
            <span>Duration:</span>
            <span class="ms-auto">
                <span>{{ getDateFormat.start }}</span>
                <span class="text-secondary"> to </span>
                <span>{{ getDateFormat.end }}</span>
            </span>
        </div>
    
    </div>
</template>

<script>
import { levelComputed } from '@/state/helpers'
//eslint-disable-next-line
import { format, parseISO } from 'date-fns'
export default {
    data:() => ({}),
    props:{
        item:{
            required: true,
        },
        current_item_is_selected:{
            default: false,
        }
    },
    computed:{
        ...levelComputed,
        getLinkedLevel(){
            let lvl = this.levels.find( level => {
                return level.id == this.item.level_id
            })
            if(lvl){
                return lvl.name
            }
            return 'N/A'
        },
        getDateFormat(){
            const start = format(parseISO(this.item.start_date), 'dd MMM');
            const end = format(parseISO(this.item.deadline), 'dd MMM');
            return {
                start: start,
                end: end,
            }
        },
    },
    methods:{},
    mounted(){},
}
</script>

<style>

</style>